const breakpoints = ["40em", "52em", "64em"];

export default {
	useBorderBox: true,
	colors: {
		text: "#000000",
		background: "#FFFFFF",
		primary: "#666666",
		secondary: "#E7E7E7",
		accent: "#0047FF",
		muted: "#bbbbbb",
		highlight: "#000000",
	},
	breakpoints,
	mediaQueries: {
		small: `@media screen and (min-width: ${breakpoints[0]})`,
		medium: `@media screen and (min-width: ${breakpoints[1]})`,
		large: `@media screen and (min-width: ${breakpoints[2]})`,
	},
	fonts: {
		body:
			'Attribut Beta, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
		heading:
			'Attribut Beta, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
		monospace: "Menlo, monospace",
	},
	shadows: {
		cardSmall: "0px 0px 9px rgba(0, 0, 0, 0.1)",
		cardLarge: "0px 0px 18px rgba(0, 0, 0, 0.2)",
		elementTiny: "0px 2px 4px rgba(0, 0, 0, 0.15)",
		elementSmall: "0px 2px 8px rgba(0, 0, 0, 0.2)",
		elementLarge: "0px 4px 18px rgba(0, 0, 0, 0.2)",
	},
	fontSizes: [10, 12, 14, 16, 18, 20, 24, 32, 48, 64],
	fontWeights: {
		light: 300,
		body: 400,
		heading: 600,
		bold: 700,
	},
	lineHeights: {
		body: 1.5,
		heading: 1.125,
	},
	letterSpacings: {
		body: "normal",
		caps: "0.2em",
	},
	space: [0, 4, 8, 16, 24, 32, 64, 128, 256, 512],
	widths: {
		maxWidth: "100%",
	},
	text: {
		headingLarge: {
			fontFamily: "heading",
			fontWeight: "body",
			lineHeight: "heading",
			fontSize: [6],
			color: "text",
		},
		headingMedium: {
			fontFamily: "heading",
			fontWeight: "body",
			lineHeight: "heading",
			textDecoration: "none",
			fontSize: [4],
			color: "text",
		},
		headingSmall: {
			fontFamily: "heading",
			fontWeight: "body",
			lineHeight: "heading",
			fontSize: [3],
			color: "text",
		},
		headingTiny: {
			fontFamily: "heading",
			fontWeight: "heading",
			lineHeight: "heading",
			fontSize: [2],
			color: "text",
		},
		bodyLarge: {
			fontFamily: "body",
			fontWeight: "body",
			lineHeight: "body",
			fontSize: [3],
			color: "text",
		},
		bodyDefault: {
			fontFamily: "body",
			fontWeight: "body",
			lineHeight: "body",
			fontSize: [2],
			color: "text",
		},
		bodySmall: {
			fontFamily: "body",
			fontWeight: "body",
			lineHeight: "body",
			fontSize: [1],
			color: "text",
		},
	},
	styles: {
		root: {
			fontFamily: "body",
			fontWeight: "body",
			p: {
				fontFamily: "body",
				fontWeight: "body",
				lineHeight: "body",
				fontSize: [1],
				color: "text",
			},
			h1: {
				fontSize: [5, 5, 6],
				fontWeight: "body",
			},
			h2: {
				fontSize: [4, 4, 5],
				fontWeight: "body",
			},
			h3: {
				fontSize: [3, 3, 4],
				fontWeight: "body",
			},
			h4: {
				fontSize: [2, 2, 3],
				fontWeight: "body",
			},
			h5: {
				fontSize: [1, 2, 2],
				fontWeight: "body",
			},
			a: {
				textDecoration: "underline",
				color: "text",
			},
			img: {
				maxWidth: "100%",
			},
		},
	},
};
